.locale-tooltip {
    max-width: 200px !important;
    border-top-right-radius: 0 !important;
    border: 1px solid black !important;
    &::before {
        top: -21px !important;
        left: 94% !important;
        margin-left: -10px !important;
        border-width: 0 0 20px 23px !important;
        border-color: transparent transparent black transparent !important;
    }

    &:after {
        top: -20px !important;
        right: 0% !important;
        margin-left: -12px !important;
        left: 95% !important;
        border-style: solid !important;
        border-width: 0 0 20px 22px !important;
        border-color: transparent transparent #b2b2b2 transparent !important;
    }

    span {
        text-align: left !important;
    }
    .tooltip-text {
        color: white;
        margin-bottom: 7px;
    }
}

@media only screen and (max-width: 1024px) {
    .locale-tooltip {
        display: none !important;
    }
}
