:root {
  --bg-color: #0079D1;
  --main-color: white;
  --body-font: Roboto;
  --secondary: #777777;
  --heading-color: black;
  --heading-font: Merriweather;
  --outline-color: #DDDDDD;
}

body {
  color: #555555;
  font-family: var(--body-font);
  letter-spacing: 0px;
}

h1, h2, h3, h4 {
  font-family: var(--heading-font);
  color: var(--heading-color);
}

h2{
  text-transform: none !important;
  margin-bottom: 30px;
}

h5, h6 {
  font-family: var(--body-font);
  color: var(--heading-color);
}

p {
  font-family: var(--body-font);
  letter-spacing: 0px;
  font-weight: 300;
}

@import 'theme/variables';
$theme-deafult: #0079D1;
@import 'theme/style';
@import 'theme/menu';
@import 'theme/responsive';

